import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'


const RandomisedControlledTrial = ({data: {strapiRandomisedControlledTrial}}) => (
  <Layout>
   
    <SEO title="PETNECK2 Randomised Controlled Trial" />
    <div class="content">
      <div class="title">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiRandomisedControlledTrial.Title}</ReactMarkdown></p>
      </div>
      <div class="col1">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiRandomisedControlledTrial.column1}</ReactMarkdown></p>
      </div>
      <div class="col2">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiRandomisedControlledTrial.column2}</ReactMarkdown></p>
      </div>
      <div class="col3">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiRandomisedControlledTrial.bottom_text}</ReactMarkdown></p>
      </div>
    </div>

  </Layout>
)

export const RandomisedControlledTrialQuesry = graphql`
  query RandomisedControlledTrialQuery {
    strapiRandomisedControlledTrial {
      Title
      column1
      column2
      bottom_text
      created_at
    }
  }
`

export default  RandomisedControlledTrial

